














































import { Component, Prop, mixins } from 'nuxt-property-decorator';

import { useSingletonPages } from '@/composables/singleton-pages';

import type { BlockShow, BlockWorker, BlockArticle } from './HorizontalList.types';

import { IconArrowRight } from '@/icons';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockHorizontalList extends mixins(ComposableMixin(useSingletonPages)) {
  // TODO: add bg type
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  @Prop(String)
  subtitle!: string

  @Prop({ type: Boolean, default: true })
  link!: boolean

  @Prop({ type: String, default: 'Visa alla' })
  linkText!: string

  @Prop({
    type: String,
    required: true,
    validator (value) {
      return ['shows', 'workers', 'articles'].includes(value);
    },
  })
  type!: string;

  // TODO: Add show type
  @Prop({ type: Array, required: false })
  shows!: BlockShow[]

  // TODO: Add workers type
  @Prop({ type: Array, required: false })
  workers!: BlockWorker[]

  // TODO: Add articles type
  @Prop({ type: Array, required: false })
  articles!: BlockArticle[]

  get blockTypeHaveContent () {
    return (type:string) => {
      switch (type) {
        case 'shows' : return this.shows.length;
        case 'workers' : return this.workers.length;
        case 'articles' : return this.articles.length;
      }
    };
  }
}
