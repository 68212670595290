import { render, staticRenderFns } from "./Worker.vue?vue&type=template&id=45ee89d0&"
import script from "./Worker.vue?vue&type=script&lang=ts&"
export * from "./Worker.vue?vue&type=script&lang=ts&"
import style0 from "./Worker.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdaptiveImageNew: require('/tmp/build_bea293ab/src/components/media/AdaptiveImageNew.vue').default,ButtonCircle: require('/tmp/build_bea293ab/src/components/button/Circle.vue').default})
